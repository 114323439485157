.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.loading-logo img {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}