.oferta-przyciski {
    margin-bottom: 0.5rem;
}
.oferta-przyciski a:not(:first-child){
    margin-left: 1.5rem;
}

.pozostale-oferty .oferta-przyciski a:not(:first-child) {
    margin-left: 1rem;
}
.oferta-wygasa {
    background-color: rgb(252, 233, 236);
    color: red;
    border: 1px solid red;
    border-radius: 5px;
    padding: 0.25rem;
}